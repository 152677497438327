import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { backgroundColor, textColor, accentColor } from '../../theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      flexGrow: 1,
    },

    menuButton: {
      marginRight: theme.spacing(2),
    },
    
    title: {
      flexGrow: 1,
      fontSize: '22.5px'
    },
  
    githubIcon: {
      fontSize: '28px',
      color: 'white',
      backgroundColor: 'black'
    },

    linkedinIcon: {
      fontSize: '32px',
      color: 'white',
      backgroundColor: 'black'
    },

    iconButton: {
      padding: '0px 4px',
      backgroundColor: 'none'
    },

    homeAvatar: {
      color: accentColor,
      backgroundColor: backgroundColor
    },

    list: {
      width: 250
    }

  }),
);
import React from 'react';
import { useStyles } from './TitleAnimationStyles';

interface TitleAnimationProps {
    x: number;
    y: number;
}

const TitleAnimation = (props: TitleAnimationProps) => {
    const { x, y } = props;
    const classes = useStyles();

    const data = []
    for (let i = 0; i < 200; i++) {
        const upper = 45;
        const lower = 8;
        const random = Math.random() * (upper - lower + 1) + lower;
        data.push(random);
    };

    let pathD = "M 147.2 29.01 L153 29 ";
    pathD += data.map((y, i) => {
        return `L ${160 + 1 + 7*i} ${y} `;
    });

    return (
        <svg height="60" width="80%" className={classes.svgOverlay}>
            <text x="0" y="40" style={{fill: '#A8FF60'}}>Scott Weitzner</text>
            <path d={pathD} style={{zIndex:100, stroke: '#A8FF60', fill: 'none', strokeWidth: '2.12'}} />
        </svg>
    )
};

export default TitleAnimation;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgOverlay: {
        paddingLeft: '75px',
        width: '100%',
        margin: 'auto',
        position: 'absolute', 
        zIndex:-1, 
        left: 0, 
        top: 0
    }
  })
);
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import TitleAnimation from '../TitleAnimation/TitleAnimation';


import { useStyles } from './NavbarStyles';

interface NavbarProps {
  x: number;
  y: number;
}

const Navbar = (props: NavbarProps) => {
  const { x, y } = props;
  const classes = useStyles();

  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState(open);
  };

  const menuList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon><MenuIcon /></ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="sticky" style={{backgroundColor: 'black'}}>
        <Toolbar>
          <IconButton edge="start" onClick={toggleDrawer(true)} className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon/>
          </IconButton>
          <Drawer anchor="left" open={drawerState} onClose={toggleDrawer(false)}>
            {menuList()}
          </Drawer>
          <Typography variant="h6" className={classes.title}>  
            <TitleAnimation x={x} y={y}/>
          </Typography>
          <IconButton aria-label='github' className={classes.iconButton}
            href="https://www.github.com/scottweitzner">
            <GitHubIcon className={classes.githubIcon}/>
          </IconButton>          
          <IconButton aria-label='linkedin' className={classes.iconButton}
            href="https://www.linkedin.com/in/scottweitzner">
            <LinkedInIcon className={classes.linkedinIcon}/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  )
};

export default Navbar;
